$(function() {
  window.addEventListener('ajax:complete', function(e) {
    var target = e.detail.event.target;
    if (target.classList.contains('js-lazy-loaded-search')) {
      var response = e.detail.xhr.responseText;
      if (response.trim().length === 0) {
        response = "Aucun résultat correspondant à la recherche n'a été trouvé.";
      }
      $('.lazy-items').html(response);
    }
  });
});
