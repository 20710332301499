import { Sortable, AutoScroll, OnSpill } from 'sortablejs/modular/sortable.core.esm';

Sortable.mount(new AutoScroll());
Sortable.mount(OnSpill);

// Revert Crm sale on top of target sale level
var RevertCrmSalesKanban = function(e) {
  const data = e.detail || {};

  const sale = document.getElementById(data.saleId);
  const saleLevel = document.getElementById(data.targetLevelId);

  if (!(sale && saleLevel)) return;

  const firstItem = saleLevel.querySelectorAll('.nc-card')[0];

  saleLevel.insertBefore(sale, firstItem);
};

var InitCrmSalesKanban = function() {
  const saleLevels = document.querySelectorAll('.kanban-col');

  saleLevels.forEach((saleLevel) => {
    Sortable.create(saleLevel, {
      scroll: true,
      scrollSensitivity: 50,
      scrollSpeed: 10,
      bubbleScroll: true,
      revertOnSpill: true,
      animation: 300,
      draggable: '.nc-card',
      sort: false,
      group: 'kanban',
      onAdd: function(evt) {
        var saleToUpdate = evt.item.dataset.updatePath;
        var targetSalesLevelId = evt.to.closest('.kanban-col').dataset.crmSalesLevelId;

        $.ajax({
          url: saleToUpdate,
          type: 'PUT',
          data: {
            crm_sale: {
              level_id: targetSalesLevelId,
            },
          },
          dataType: 'script',
          //FIXME Load jquery-ujs through webpack
          beforeSend: function(xhr) {
            var token = $('meta[name="csrf-token"]').attr('content');
            xhr.setRequestHeader('X-CSRF-Token', token);
          },
        });
      },
    });
  });
};

document.addEventListener('DOMContentLoaded', InitCrmSalesKanban);
document.addEventListener('kanban:revert', RevertCrmSalesKanban);
